@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiDataGrid-virtualScroller,
.MuiDataGrid-main {
    min-height: 100px;
}

[type='text']:focus, [type='email']:focus, 
[type='url']:focus, [type='password']:focus, 
[type='number']:focus, [type='date']:focus,
[type='datetime-local']:focus, [type='month']:focus, 
[type='search']:focus,
[type='tel']:focus, [type='time']:focus,
[type='week']:focus, 
[multiple]:focus, 
textarea:focus,
select:focus{
    --tw-ring-color: none !important;
    --tw-ring-shadow: none !important;
}

.react-datepicker-popper {
  z-index: 10000;
}

.center-header .MuiDataGrid-colCell {
  text-align: center;
}

/**
 * Loader icon
 */

 .tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.loader-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(194, 194, 194);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(194, 194, 194) transparent transparent transparent;
}
.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* FILE FileUploader */
.inner-container {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-container .sub-header {
  text-align: center;
  font-size: 18px;
  margin-bottom: 7px;
}
.inner-container .draggable-container-hovered {
  border: 2px dashed white !important;
}
.inner-container .draggable-container {
  min-width: 90%;
  max-width: 95%;
  min-height: 4rem;
  max-height: 8em;
  background-color: rgba(147, 148, 148, 0.23);
  padding: 6px;
  border-radius: 4px;
  border: 2px dashed rgba(95, 92, 92, 0.2);
  font-size: 14px;
  display: flex;
  margin-bottom: 7px;
  position: relative;
  overflow: hidden;
}
.inner-container .draggable-container .hovered {
  border: 2px dashed white !important;
}
.inner-container .draggable-container #file-browser-input {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  color: transparent;
  opacity: 0;
  z-index: 10;
}
.inner-container .draggable-container .files-preview-container {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 99;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
  padding: 5px;
}
.inner-container .draggable-container .files-preview-container .file {
  width: 5em;
  height: 58px;
  background-color: rgba(101, 97, 97, 0.34);
  position: relative;
  margin-left: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 14px 3px rgba(15, 15, 15, 0.2);
  flex-shrink: 0;
}
.inner-container .draggable-container .files-preview-container .file img {
  width: 100%;
  height: 100%;
}
.inner-container
  .draggable-container
  .files-preview-container
  .file
  .container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: background, 160ms ease-in-out;
}
.inner-container
  .draggable-container
  .files-preview-container
  .file
  .container:hover {
  background-color: rgba(154, 151, 151, 0.29);
}
.inner-container
  .draggable-container
  .files-preview-container
  .file
  .container:hover
  .remove-btn {
  visibility: visible;
}
.inner-container
  .draggable-container
  .files-preview-container
  .file
  .container
  .remove-btn {
  color: #ca4240;
  width: fit-content;
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 99;
  cursor: pointer;
  visibility: hidden;
}
.inner-container
  .draggable-container
  .files-preview-container
  .file
  .container
  .progress-bar {
  position: absolute;
  width: 53px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-self: center;
}
.inner-container .draggable-container .helper-text {
  position: absolute;
  display: flex;
  justify-self: center;
  align-self: center;
  left: 50%;
  transform: translateX(-50%);
  color: #797676;
  text-align: center;
}
.inner-container .draggable-container .file-browser-container {
  position: absolute;
  display: flex;
  justify-self: center;
  align-self: center;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}

/* Credit Lookup Button Padding */

.creditLookupButton {
  margin-top: 30px;
}

/* Credit Lookup Button Padding */
